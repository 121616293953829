import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { selectThemeOptionsState } from 'themeSlice/selectors';
import { useThemeSlice } from 'themeSlice';
// import { setEnableMobileMenu } from 'themeSlice/selectors';

import { AdminRoles as ADMIN_ROLES } from 'auth/Roles';

import {
  MainNav,
  UsersNav,
  HotelBookingNav,
  GroupLeaderNav,
  HotelManagementNav,
  WeddingManagementNav,
} from './NavItems';

import { IModuleList, ISubModule } from './types';

function Nav() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { actions } = useThemeSlice();

  const [role, setRole] = useState<ADMIN_ROLES>(null as ADMIN_ROLES);
  const themeOptions = useSelector(selectThemeOptionsState);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('loginUser'));
    setRole(user ? user.profile.role : '');
  }, []);

  /**Todo: rewrite this into using ACTUAL react code/approach */
  const toggleMobileSidebar = React.useCallback(
    e => {
      e.preventDefault();
      const router = e.currentTarget.getAttribute('href');
      const elems = document.querySelector('.active');
      if (elems !== null) {
        elems.classList.remove('active');
      }
      e.currentTarget.classList.add('active');
      dispatch(actions.setEnableMobileMenu(!themeOptions.enableMobileMenu));
      dispatch(actions.setEnableClosedSidebar(true));
      history.push(router);
    },
    [actions, dispatch, history, themeOptions],
  );

  const filterModulesByRole = (role: ADMIN_ROLES) => {
    const _rec = (...modules: IModuleList[]): IModuleList[] => {
      return modules.map(module => {
        return module.filter(item => {
          const isPage = 'roles' in item;
          if (isPage) {
            // item is page
            return item.roles.some(r => r === role);
          } else {
            // item is sub-module
            const [submodules] = _rec((item as ISubModule).content);
            return submodules.length;
          }
        });
      });
    };
    return _rec(
      MainNav,
      HotelBookingNav,
      HotelManagementNav,
      GroupLeaderNav,
      WeddingManagementNav,
      UsersNav,
    );
  };

  const menus = React.useMemo(() => {
    if (!role) return [];
    const [menu, reservations, manageContent, groups, salesJourney, accounts] =
      filterModulesByRole(role);
    const items = [
      {
        header: 'Menu',
        contents: menu,
      },
      {
        header: 'Reservations',
        contents: reservations,
      },
      {
        header: 'Manage Content',
        contents: manageContent,
      },
      {
        header: 'Groups',
        contents: groups,
      },
      {
        header: 'Sales Journey',
        contents: salesJourney,
      },
      {
        header: 'accounts',
        contents: accounts,
      },
    ];
    return items.filter(item => item.contents.length);
  }, [role]);

  return (
    <>
      {menus.length
        ? menus.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <h5 className="app-sidebar__heading">{item.header}</h5>
                <MetisMenu
                  content={item.contents}
                  // onSelected={toggleMobileSidebar}
                  activeLinkFromLocation
                  className="vertical-nav-menu"
                  iconNamePrefix=""
                  classNameStateIcon="pe-7s-angle-down"
                />
              </React.Fragment>
            );
          })
        : 'loading...'}
    </>
  );
}

export default withRouter(Nav);
