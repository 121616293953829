import React, { useCallback } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Button, Container } from 'reactstrap';
import { History, LocationState } from 'history';
import { TransitionGroup } from 'react-transition-group';
import { useSelector } from 'react-redux';

import { selectUser } from '../../UserPages/slice/selectors';

// import PageTitleAlt2 from '../../../Layout/AppMain/PageTitleAlt2';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import {
  HotelBookingNav,
  HotelManagementNav,
} from '../../../Layout/AppNav/NavItems';

interface LandingPageProps {
  history: History<LocationState>;
}

export default function LandingDashboard(props: LandingPageProps) {
  const userDetails = useSelector(selectUser);
  const GoToHotelMenu = () => {
    props.history.push('/hotels/hotel-content/');
  };
  const GoToHBSIMenu = () => {
    props.history.push('/hbsi/search');
  };
  const GoToGroupLeaderMenu = () => {
    props.history.push('/group/group-leader');
  };
  const GoToSalesLeadMenu = () => {
    props.history.push('/salesleads/guest-list');
  };
  const GoToLegacyCrmSalesLeadMenu = () => {
    props.history.push('/salesleads/crm-sales-leads');
  }

  const GoToClientMenu = () => {
    props.history.push('/user/guest-list-new');
  };
  const isAvailableforRole = useCallback(
    (List) => (
      List[0].content.filter((item) => item.roles.includes(userDetails.profile.role)).length > 0
    ),
    [userDetails.profile.role]
  );
  return (
    <>
      <TransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="Welcome Destify PTID Administration"
          // subheading="From all-inclusive packages, to boutique resort buy-outs, to elegant off-property venues, we can satisfy your every wedding intricacy!"
          // icon="pe-7s-home icon-gradient bg-ripe-malin"
        />
      </TransitionGroup>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>
                  Hello {userDetails.profile.name}, This is your {process.env.REACT_APP_ENV}{' '}
                  destify admin dashboard
                </CardTitle>
                <Row>
                  <Col xl="4" sm="6">
                    <Button
                      disabled={!isAvailableforRole(HotelManagementNav)}
                      onClick={() => GoToHotelMenu()}
                      className="btn-icon-vertical mb-3 btn-transition btn-block"
                      outline
                      color="primary"
                      data-testid="button-hotel-content"
                    >
                      <i className="lnr-apartment  btn-icon-wrapper"> </i>
                      <span className="badge badge-primary badge-dot badge-dot-sm badge-dot-inside">
                        {' '}
                      </span>
                      Manage Content
                    </Button>
                  </Col>

                  <Col xl="4" sm="6">
                    <Button
                      disabled={!HotelBookingNav.filter((item) => item.roles.includes(userDetails.profile.role)).length}
                      onClick={() => GoToHBSIMenu()}
                      className="btn-icon-vertical mb-3 btn-transition btn-block"
                      outline
                      color="primary"
                    >
                      <i className="lnr-screen btn-icon-wrapper"> </i>
                      <span className="badge badge-dark badge-dot badge-dot-lg badge-dot-inside">
                        {' '}
                      </span>
                      Reservations
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button
                      onClick={() => GoToGroupLeaderMenu()}
                      className="btn-icon-vertical mb-3 btn-transition btn-block"
                      outline
                      color="primary"
                      data-testid="button-group-leader"
                    >
                      <i className="pe-7s-users btn-icon-wrapper"> </i>
                      <span className="badge badge-dark badge-dot badge-dot-lg badge-dot-inside">
                        {' '}
                      </span>
                      Group Leaders
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button
                      onClick={() => GoToSalesLeadMenu()}
                      className="btn-icon-vertical mb-3 btn-transition btn-block"
                      outline
                      color="secondary"
                      data-testid="button-sales-journey-sales-leads"
                    >
                      <i className="pe-7s-headphones btn-icon-wrapper"> </i>
                      <span className="badge badge-dark badge-dot badge-dot-lg badge-dot-inside">
                        {' '}
                      </span>
                      Sales Journey Sales Leads
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button
                      onClick={() => GoToLegacyCrmSalesLeadMenu()}
                      className="btn-icon-vertical mb-3 btn-transition btn-block"
                      outline
                      color="focus"
                      data-testid="button-sales-journey-legacy-crm-sales-leads"
                    >
                      <i className="pe-7s-gleam btn-icon-wrapper"> </i>
                      <span className="badge badge-dark badge-dot badge-dot-lg badge-dot-inside">
                        {' '}
                      </span>
                      Legacy CRM Sales leads
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button
                      onClick={() => GoToClientMenu()}
                      className="btn-icon-vertical mb-3 btn-transition btn-block"
                      outline
                      color="info"
                      data-testid="button-client-account"
                    >
                      <i className="pe-7s-user btn-icon-wrapper"> </i>
                      <span className="badge badge-dark badge-dot badge-dot-lg badge-dot-inside">
                        {' '}
                      </span>
                      Client Accounts
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
